<template>
  <div class="areaWorkRecord">
    <!-- 项目区域-作业记录 -->
    <el-container>
      <el-main>
        <head-layout
          head-title="作业记录"
          :showIcon="false"
        />
        <grid-layout
          class="itemListGrid"
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
        >
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {
  prjAreaCheckPage
} from '@/api/safe/projectArea.js'

import { mapGetters } from "vuex";
// import {getDictionaryCode} from '@/api/system/dictbiz.js'
export default {
  components: {
    HeadLayout,
    GridLayout,
  },

  props: {
    areaId: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      // prjId: "", // 项目id
      selectionList: [],
      
    };
  },

  computed: {
    ...mapGetters(["permission", "colorName"]),

    tableOptions() {
      return {
        menu: false,
        selection: false,
        column: [
          {
            label: "作业票编号",
            prop: "",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业票类型",
            prop: "",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业实施时间",
            prop: "",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业负责人",
            prop: "",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业状态",
            prop: "",
            align: "center",
            overHidden: true,
            dicUrl: '',
            props: {
              value: 'dictKey',
              label: 'dictValue',
            }
          },
        ],
      };
    },
  },

  methods: {
    init () {
      this.onLoad(this.page)
    },
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      // this.tableLoading = true;
      // this.page = page;
      // params.prjAreaId = this.areaId || '';
      // prjAreaCheckPage(
      //   page.currentPage,
      //   page.pageSize,
      //   Object.assign(params, this.query)
      // ).then((res) => {
      //   const data = res.data.data;
      //   // this.$refs.gridLayOut.page.total = data.total;
      //   this.page.total = data.total
      //   this.tableData = data.records;
      //   this.tableLoading = false;
      //   //重新渲染表格，解决勾选框错行问题
      //   this.$nextTick(() => {
      //     this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
      //   });
      //   this.$refs.gridLayOut.selectionClear();
      // });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page);
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page);
    },
    headAdd() { },

  },
};
</script>

<style lang="scss" scoped>
.areaWorkRecord{
  height: 100%;
  .el-main{
      overflow: hidden;
  }
}
::v-deep #gridLayout {
    height: calc(100% - 100px) !important;
  }
  ::v-deep .avue-crud {
    height: 100% !important;
    .el-card{
        height: 100% !important;
        overflow: hidden;
      .el-card__body  {
        height: 100% !important;
    
        .el-form {
          height: 100% !important;
    
          .el-table {
            height: 100% !important;
            max-height: 100% !important
          }
        }
      }
    }
  }
</style>
