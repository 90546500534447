<template>
    <div class="prj_area_info">
        <el-container>
            <el-main>
                <head-layout
                    head-title="项目区域信息"
                    :head-btn-options="headBtnOptions"
                    @head-submit="headSubmit"
                    @head-goBack="headGoBack"
                />
                <el-row style="margin-top: 10px;">
                    <el-col :span="18">
                        <avue-form
                            :option="formOption"
                            ref="avueForm"
                            v-model="dataForm"
                            @submit="handleSubmit"
                            v-loading="loading"
                        >
                            <template slot="areaCode">
                                <el-input
                                placeholder="请输入区域编号"
                                v-model="dataForm.areaCode"
                                maxlength="64"
                                :disabled="dataForm.id"
                                >
                                <span slot="append">
                                    自动生成
                                    <el-switch
                                    v-model="isAutomatic"
                                    active-color="#13ce66"
                                    @change="handleSwitch"
                                    :disabled="dataForm.id"
                                    >
                                    </el-switch>
                                </span>
                                </el-input>
                            </template>
                        </avue-form>
                    </el-col>
                    <el-col :span="6">
                        <div
                            v-if="dataForm.id"
                            class="qrcode"
                        >
                            <img ref="qrcodeBox" :src="qrCodeSrc" style="width: 150px;height:150px" alt="">
                            <div>
                                <el-button size="mini" @click.native="download">下载</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <div v-if="dataForm.id" class="area_tabs">
                    <el-tabs v-model="activeName" @tab-click="handleActive">
                        <el-tab-pane label="负责人员" name="userList">
                            <userList ref="userList" :areaId="dataForm.id" :deptSegment="deptSegment"/>
                        </el-tab-pane>
                        <el-tab-pane label="检查清单" name="checkList">
                            <checkList ref="checkList" :areaId="dataForm.id"/>
                        </el-tab-pane>
                        <el-tab-pane label="检查记录" name="checkRecord">
                            <checkRecord ref="checkRecord" :areaId="dataForm.id"/>
                        </el-tab-pane>
                        <el-tab-pane label="作业记录" name="workRecord">
                            <workRecord ref="workRecord" :areaId="dataForm.id"/>
                        </el-tab-pane>
                        <el-tab-pane label="问题清单" name="proList">
                            <proList ref="proList" :areaId="dataForm.id"/>
                        </el-tab-pane>
                    </el-tabs>

                </div>
            </el-main>
        </el-container>
      <qrcodeDialog ref="qrcodeDialog"></qrcodeDialog>

    </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { getPageCode } from "@/api/system/serialNumber";
import { getAreaClassLinSubmit, getAreaClassLinDetail } from "@/api/safe/projectArea";
import userList from './userList.vue'
import checkList from './checkList.vue'
import checkRecord from './checkRecord.vue'
import workRecord from './workRecord.vue'
import proList from './proList.vue'
import qrcodeDialog from "../../../../projectSettings/regionManage/qrcode.vue";

export default {
    components: {
        HeadLayout,
        GridLayout,
        userList,
        checkList,
        checkRecord,
        workRecord,
        proList,
        qrcodeDialog,
    },
    props: {
        prjId: {
            type: String,
            default: ''
        },
        typeId: {
            type: String,
            default: ''
        },
        areaId: {
            type: String,
            default: ''
        },
        deptSegment: {
            type: String,
            default: ''
        },
    },
    data () {
         return {
            formOption: {
                submitBtn: false,
                emptyBtn: false,
                column: [
                    {
                        label: '区域编号',
                        labelSuffix: "",
                        prop: "areaCode",
                        type: 'input',
                        rules: [{ required: true, message: '请填写区域编号',trigger:'change'}],
                        formslot: true,
                        span: 12,
                        maxlength: 250,
                    },
                    {
                        label: '区域名称',
                        labelSuffix: "",
                        prop: 'areaName',
                        type: 'input',
                        rules: [{
                            required: true,
                            message: '请填写区域名称',
                            trigger: 'blur',
                        }],
                        span: 12,
                        maxlength: 250,
                    },
                    {
                        label: '所属分类',
                        labelSuffix: "",
                        prop: 'typeId',
                        type: 'tree',
                        dicUrl: `/api/sinoma-hse-prj/prjbasisareatype/tree?prjId=${this.$route.query.id}`,
                        props: {
                            label: 'typeName',
                            value: 'id'
                        },
                        span: 12,
                    },
                    // 20240822：后端暂无字段
                    // {
                    //     label: '是否启用',
                    //     labelSuffix: "",
                    //     prop: 'status',
                    //     type: 'switch',
                    //     span: 12,
                    //     value: true
                    // },
                    {
                        label: '备注说明',
                        labelSuffix: "",
                        prop: 'remark',
                        type: 'textarea',
                        maxlength: 255,
                        showWordLimit: true,
                        span: 24,
                    },
                ]
            },
            dataForm: {},
            loading: false,
            isAutomatic: true,
            activeName: 'userList',
            cedType: '',
            qrCodeSrc: ''
         }
    },

    computed: {
        headBtnOptions() {
            if (this.$route.query.type != "view") {
                return [
                    {
                        label: '保存',
                        emit: "head-submit",
                        type: "button",
                        icon: "",
                    },
                    {
                        label: '返回',
                        emit: "head-goBack",
                        type: "button",
                        icon: "",
                    },
                ];
            }
        },

    },

    created () {
        this.cedType == this.areaId ? 'edit' : 'add'
        if(this.areaId){
            this.getDetail(this.areaId)
        } else {
            // 新增
            this.handleSwitch()
            this.dataForm.typeId = this.typeId
            this.dataForm.prjId = this.prjId
        }
        // this.init()
    },

    methods: {
        getDetail (id) {
            getAreaClassLinDetail(id).then(res => {
                this.dataForm = res.data.data
                this.qrCodeSrc = "data:image/png;base64," + this.dataForm.baseCode.codeUrl;
                this.$nextTick(()=>{
                    this.handleActive(this.activeName)
                })
            })
        },

        // 基本信息记录编号是否自动生成
        async handleSwitch() {
            if (this.isAutomatic) {
                await this.getPageCode();
            } else {
                this.dataForm.areaCode = "";
            }
        },
        async getPageCode() {
            // 获取编码
            await getPageCode({ ruleCode: "area_code" }).then((res) => {
                this.$set(this.dataForm, "areaCode", res.data.data || "");
                this.$forceUpdate();
            });
        },

        headGoBack () {
            this.$emit('callback')
        },

        headSubmit () {
            this.$refs.avueForm.submit()
        },

        handleSubmit () {
            this.$refs.avueForm.validate((valid, done) => {
                if(valid) {
                    delete this.dataForm.$typeId
                    let params = JSON.parse(JSON.stringify(this.dataForm));
                    params.qrCodeContent = `?regionCode=${this.dataForm.areaCode}&type=projectArea` // 扫码传参内容：暂未确定
                    getAreaClassLinSubmit(params)
                    .then((res) => {
                        this.getDetail(res.data.data.id)
                        done()
                        this.$message.success("操作成功");
                    })
                    .catch(() => {
                        done()
                    });
                }
            })
        },

        handleActive (value) {
            const {id,areaCode,areaName} = this.dataForm
            this.$refs[this.activeName].init({areaName, areaId: id, areaCode})
        },

        download () {
            this.$refs.qrcodeDialog.init(
                this.qrCodeSrc,
                this.dataForm.areaName,
                this.dataForm.areaCode,
                'haveTitle'
            );
        }

    }

}
</script>

<style lang="scss" scoped>
.qrcode{
    width: 100%;
    text-align: center;
}
::v-deep .el-tabs__nav-scroll{
    width: 100% !important;
}
.el-container{
    height: 100%;
}
.area_tabs{
    height: calc(100% - 300px);
}
.el-tabs .el-tabs--top{
    height: 100%;
}
::v-deep .el-tabs__content{
    height: calc(100% - 70px) !important;
}
::v-deep .el-tabs__nav-scroll{
    padding-left: 12px !important;
}

</style>
